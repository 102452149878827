<template>
    <div class="verification-code-login">
        <img
            v-if="templateInfo.loginMethod.find(e => ['2', '3'].includes(e))"
            class="qrcode-btn"
            :src="imgQrcodeBtn"
            alt="qrcodeBtn"
            @click="handleLoginMethodSwitch('2')"
        >
        <div class="login-header">
            <div
                v-if="templateInfo.loginMethod.filter(e => ['1', '4'].includes(e)).length === 1"
                class="login-title_welcome"
            >欢迎登录</div>
            <template v-else>
                <div class="login-title" @click="handleLoginMethodSwitch('1')">账号密码登录</div>
                <div class="login-title login-title_active">手机快捷登录</div>
            </template>
        </div>
        <el-form ref="loginForm" class="login-form" :model="loginForm" :rules="loginRules" label-width="0px">
            <!-- 手机号 -->
            <el-form-item prop="phoneNumber">
                <div class="login-form-item login-form-input">
                    <i class="login-form-input-icon el-icon-user"></i>
                    <el-input v-model="loginForm.phoneNumber" placeholder="请输入手机号" clearable></el-input>
                </div>
            </el-form-item>
            <!-- 短信验证码 -->
            <el-form-item prop="smsCode">
                <div class="login-form-item login-form-input">
                    <i class="login-form-input-icon el-icon-message"></i>
                    <el-input
                        v-model="loginForm.smsCode"
                        placeholder="短信验证码"
                        clearable
                        @keyup.enter.native="handleLogin"
                    ></el-input>
                    <div class="login-form-input-sms-code">
                        <span v-if="loginForm.smsCodeCountdown > 0">{{ loginForm.smsCodeCountdown }}秒</span>
                        <span v-else @click="handleSmsVerificationCodeClick">获取验证码</span>
                    </div>
                </div>
            </el-form-item>
            <!-- 图片验证码 -->
            <el-form-item prop="code">
                <div class="login-form-item">
                    <div class="login-form-input">
                        <i class="login-form-input-icon el-icon-postcard"></i>
                        <el-input
                            v-model="loginForm.code"
                            placeholder="验证码"
                            clearable
                            @keyup.enter.native="handleLogin"
                        ></el-input>
                    </div>
                    <!-- 网络图片验证码 -->
                    <img
                        v-if="loginForm.codeUrl"
                        class="login-form-item-code"
                        :src="loginForm.codeUrl"
                        alt="code"
                        @click="handlePictureVerificationCodeClick"
                    >
                    <!-- 静态图片验证码占位 -->
                    <img
                        v-else
                        class="login-form-item-code"
                        :src="imgVerificationCode"
                        alt="code"
                    >
                </div>
            </el-form-item>
            <div class="remember-password" v-if="templateInfo.rememberme == '1'">
                <el-checkbox v-model="loginForm.rememberPassword">记住登录状态</el-checkbox>
            </div>
            <!-- 登录按钮 -->
            <div
                class="login-button"
                :class="{ 'login-button_loading': loginButtonLoading }"
                @click="handleLogin">
                <i v-show="loginButtonLoading" class="el-icon-loading"></i>
                <span>登录</span>
            </div>
        </el-form>
    </div>
</template>

<script>
export default {
    name: 'VerificationCodeLogin',
    components: {},
    props: {
        templateInfo: {
            type: Object
        },
        loginForm: {
            type: Object
        },
        loginRules: {
            type: Object
        },
        loginButtonLoading: {
            type: Boolean
        }
    },
    data() {
        return {}
    },
    computed: {
        imgQrcodeBtn() {
            return require('@/assets/images/loginPageTemplate/theme3/login_box_qrcode_btn.png')
        },
        imgVerificationCode() {
            return require('@/assets/images/loginPageTemplate/theme3/login_box_verification_code.png')
        }
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleLoginMethodSwitch(loginMethod) {
            this.$eventDispatch('loginMethodSwitch', loginMethod);
        },
        handleSmsVerificationCodeClick() {
            this.$eventDispatch('smsVerificationCodeClick', this.smsCodeCountdown);
        },
        handlePictureVerificationCodeClick() {
            this.$eventDispatch('pictureVerificationCodeClick', this.loginForm.codeUrl);
        },
        handleLogin() {
            this.$refs.loginForm.validate((valid) => {
                // console.log("[handleLogin]valid:", valid)
                if (valid) {
                    this.$eventDispatch('login', this.loginForm);
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
$coefficient: var(--coefficient, 1px);

.verification-code-login {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    .qrcode-btn {
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100 * #{$coefficient});
        height: calc(100 * #{$coefficient});
        object-fit: contain;
        display: inline-block;
        cursor: pointer;
    }

    .login-header {
        padding-top: calc(43 * #{$coefficient});
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .login-title_welcome {
            font-size: calc(30 * #{$coefficient});
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #05042A;
        }

        .login-title {
            font-size: calc(22 * #{$coefficient});
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            cursor: pointer;

            +.login-title {
                margin-left: calc(36 * #{$coefficient});
            }
        }
        .login-title_active {
            font-size: calc(28 * #{$coefficient});
            color: #05042A;
        }
    }

    .login-form {
        padding: 0 calc(46 * #{$coefficient});

        ::v-deep .el-form-item {
            margin-top: calc(30 * #{$coefficient});
            margin-bottom: 0;

            .el-form-item__error {
                padding-top: calc(2 * #{$coefficient});
                font-size: calc(12 * #{$coefficient});
            }
        }

        ::v-deep .el-input {
            font-size: calc(18 * #{$coefficient});

            .el-input__inner {
                border: unset;
                height: auto;
                line-height: 1;
            }

            .el-input__suffix {
                .el-input__suffix-inner {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 100%;
                }

                .el-input__icon {
                    width: auto;
                    line-height: 1;
                    font-size: calc(16 * #{$coefficient});
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    +.el-input__icon {
                        margin-left: calc(10 * #{$coefficient});
                    }
                }

                .el-input__validateIcon {
                    display: none;
                }
            }
        }
        ::v-deep .el-input--suffix {
            .el-input__inner {
                padding-right: calc(36 * #{$coefficient});
            }
        }

        .login-form-item {
            display: flex;
            align-items: center;
        }

        .login-form-input {
            height: calc(48 * #{$coefficient});
            padding: calc(14 * #{$coefficient});
            border-bottom: 1px solid #DFDFDF;
            box-sizing: border-box;
            display: flex;
            align-items: center;

            .login-form-input-icon {
                flex-shrink: 0;
                color: #C9C9C9;
                font-size: calc(20 * #{$coefficient});
                padding-right: calc(14 * #{$coefficient});
                border-right: 1px solid #C9C9C9;
            }

            .login-form-input-sms-code {
                flex-shrink: 0;
                font-size: calc(18 * #{$coefficient});
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 1;
                color: #3C7FFF;
                padding-left: calc(10 * #{$coefficient});
                border-left: 1px solid #C9C9C9;
                cursor: pointer;
            }
        }
        .login-form-input:hover {
            border-color: #3C7FFF;

            .login-form-input-icon {
                color: #3C7FFF;
                border-color: #3C7FFF;
            }
        }

        .login-form-item-code {
            width: calc(96 * #{$coefficient});
            height: calc(37 * #{$coefficient});
            object-fit: contain;
            display: inline-block;
            margin-left: calc(40 * #{$coefficient});
            cursor: pointer;
        }

        .remember-password {
            display: flex;
            justify-content: space-between;
            margin-top: calc(20 * #{$coefficient});
            line-height: 1;

            ::v-deep .el-checkbox {
                display: flex;
                flex-direction: row;
                align-items: center;

                .el-checkbox__inner {
                    width: calc(16 * #{$coefficient});
                    height: calc(16 * #{$coefficient});
                    min-width: 9px;
                    min-height: 9px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &::after {
                        top: calc(2 * #{$coefficient});
                        left: calc(5 * #{$coefficient});
                        width: calc(3 * #{$coefficient});
                        height: calc(7 * #{$coefficient});
                    }
                }
                .el-checkbox__label {
                    padding-left: calc(10 * #{$coefficient});
                    line-height: calc(19 * #{$coefficient});
                    font-size: calc(16 * #{$coefficient});
                }
            }

            .text {
                /deep/ span {
                    font-size: calc(16 * #{$coefficient});
                }
            }
        }

        .login-button {
            width: 100%;
            height: calc(48 * #{$coefficient});
            background: #3C7FFF;
            border-radius: calc(10 * #{$coefficient});
            margin-top: calc(30 * #{$coefficient});
            text-align: center;
            line-height: calc(48 * #{$coefficient});
            font-size: calc(18 * #{$coefficient});
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            cursor: pointer;

            .el-icon-loading {
                margin-right: calc(6 * #{$coefficient});
            }
        }
        .login-button_loading {
            cursor: not-allowed;
            pointer-events: none;
            filter: opacity(30%);
            -webkit-filter: opacity(30%);
        }
    }
}
</style>
